<!-- @format -->

<template>
	<section class="section-map">
		<div class="container-fluid map-container">
			<!-- <div class="circle-btn plus"><img src="@assets/img/map/plus.svg" alt="+" /></div>
			<div class="circle-btn minus"><img src="@assets/img/map/minus.svg" alt="-" /></div>
			<div class="circle-btn position">
				<img src="@assets/img/map/position.svg" alt="" />
			</div> -->
			<ui-map ref="map" :markers="markers" :isPolyline="false" :filter="mapFilter" :is-loader="isLoading" height="600px"
				@filter="getRouteClusters" :isSearchControl="false" :center="center" v-if="center?.length > 0">
				<template #marker-popup="{ marker }">
					<map-popup @close="onClosePopup" @start="onStart" @more="onMore" :img="marker.point.DetailUrl"
						:title="marker.point.Title" :subTitle="marker.point.Description"></map-popup> </template></ui-map>
		</div>
	</section>
</template>

<script lang="ts" setup>
import { MapFilterModel, MapPointMarkerModel } from "@/components/controls/ui-map";
import { RouteFilterModel } from "@/components/controls/ui-map/models-ext/RouteFilterModel";
import RouteItemClusterModel from "@/components/controls/ui-map/models-ext/RouteItemClusterModel";
import { MainAdapterService, Position } from "@/components/pages";
import Vue, { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const adapter = new MainAdapterService();
/**
 * * Идет ли загрузка
 */
const isLoading = ref(false);
const map = ref();
/**
 * * Фильтр
 */

const filter = ref(new RouteFilterModel());
const clusters = ref(Array<RouteItemClusterModel>());
const center = ref([])

async function getGeoStart(): Promise<string[]> {
	if ("geolocation" in navigator) {
		try {
			const position = await new Promise<GeolocationPosition>((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			});
			const latitude = position.coords.latitude.toString();
			const longitude = position.coords.longitude.toString();
			console.log(`Широта: ${latitude}, Долгота: ${longitude}`);
			return [latitude, longitude];
		} catch (error) {
			console.error("Ошибка при получении геолокации: ", error);
			return getGeoLand(); // Если ошибка, используем запасной вариант
		}
	} else {
		console.log("Геолокация не поддерживается вашим браузером.");
		return getGeoLand();
	}
}

function getGeoLand(): string[] {
	let lang = "ru"
	lang = localStorage.getItem("lang") || 'en'
	let positions = ["55.755826", "37.6173"];
	switch (lang) {
		case 'ru': {
			positions = ["55.755826", "37.6173"];
			break
		}
		case 'en': {
			positions = ["51.5074", "-0.1278"]
			break
		}
		case 'lv': {
			positions = ["56.9496", "24.1052"]
			break

		}
	}
	return positions
}
/**
 * * Список точек
 */
const markers = computed(() => clusters.value.map((x) => new MapPointMarkerModel(x)));
const router = useRouter();
/**
 * * Фильтр для карты
 */
const mapFilter = computed(
	() =>
		new MapFilterModel({
			Zoom: filter.value.Zoom,
			NorthEast: filter.value.NorthEast,
			SouthEast: filter.value.SouthEast,
		})
);
const onClosePopup = () => {
	map.value.onClosePopUp();
};
const onStart = () => {
	router.push({ name: "app" });
};
const onMore = () => {
	router.push({ name: "app" });
};
async function getRouteClusters(value: MapFilterModel) {
	filter.value.Zoom = value.Zoom;
	filter.value.NorthEast = value.NorthEast;
	filter.value.SouthEast = value.SouthEast;
	clusters.value = await adapter.getRouteClusters(mapFilter.value);
	console.log("sssss", mapFilter.value, clusters.value)
}

onMounted(async () => {
	clusters.value = await adapter.getRouteClusters(mapFilter.value);
	center.value = await getGeoStart();
});
</script>

<style lang="less" scoped>
.container-fluid.map-container {
	margin: 0px !important;
	padding: 0px !important;
	max-width: 100% !important;
}

.section-map {
	// background-color: var(--background-color);
	max-height: 90vh;
	overflow: hidden;
}

.map-container {
	position: relative;

	.circle-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 100px;
		background: #fff;
		position: absolute;
		font-size: 32px;
		color: #5547c2;
		cursor: pointer;

		&.plus {
			right: 20px;
			bottom: 220px;
			z-index: 500;
			border-radius: 50%;
		}

		&.minus {
			right: 20px;
			bottom: 115px;
			z-index: 500;
			border-radius: 50%;
		}

		&.position {
			right: 20px;
			bottom: 10px;
			z-index: 500;
			border-radius: 50%;
		}
	}
}

#map {
	max-width: 100%;
	width: 100%;
	height: 700px;
}

@media all and (max-width: 576px) {
	.map-container {
		padding-left: 0;
		padding-right: 0;
	}
}
</style>
