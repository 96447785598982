import PageRequest from "@/api/dataSource/api/PaginationHelper/Lib/PageRequest";
import { MapFilterModel } from "@/components/controls/ui-map";
import { CropImageModel } from "@/components/controls/ui-map/models-ext/CropImageModel";
import MapRouteClusterItemResponse from "@/components/controls/ui-map/models-ext/MapRouteClusterItemResponse";
import { PaginationModel } from "@/components/controls/ui-map/models-ext/PaginationModel";
import { RouteFilterModel } from "@/components/controls/ui-map/models-ext/RouteFilterModel";
import RouteItemClusterModel from "@/components/controls/ui-map/models-ext/RouteItemClusterModel";

import moment from "moment";
import { ref } from "vue";
import { GetMapRouteClustersRequest } from "./models";
// import MapCoordinates from "./models/MapCoordinates";
import MapPointCoordinates from "@/components/controls/ui-map/models-ext/MapPointCoordinates";
import axios from "axios";
interface MapCoordinates {
  Longitude: string;
  Latitude: string;
}

interface ClusterRequest {
  LeftTopPoint: MapCoordinates;
  RightBottomPoint: MapCoordinates;
  ZoomLevel: number;
  Localization: number;
}
interface IncomingData {
  id: number;
  count: number;
  type: number;
  geoHash: string | null;
  languageId: number;
  position: {
    longitude: number;
    latitude: number;
  };
  mapRoute: {
    id: number;
    title: string;
    description: string;
    type: number;
    transmission: number;
    previewUrl: string;
    x: number;
    y: number;
    detailUrl: string;
  };
}

interface ConvertedData {
  Id: number;
  Count: number;
  Type: number;
  LanguageId: number;
  Position: {
    Longitude: number;
    Latitude: number;
  };
  MapRoute?: {
    Id: number;
    Title: string;
    Description: string;
    Distance?: number;
    Type: number;
    Transmission: number;
    Price?: number;
    CommentsCount?: number;
    PointsCount?: number;
    PreviewUrl: string;
    Owner?: {
      UserId: number;
      NickName: string;
      PhotoUrl: string;
    };
    PassedStatus?: number;
    FirstMapPoint?: {
      MapPointId: number;
      Latitude: number;
      Longitude: number;
    };
    IsBuy?: boolean;
    IsFavorite?: boolean;
    DistanceByCurrentPosition?: number;
  };
}
interface ClusterResponse {
  // Определите структуру ответа здесь
}
export default class MainAdapterService {
  useApiStore: any = Object();
  /**
   * * Фильтр
   */
  filter = ref(new RouteFilterModel());
  /**
   * * Идет ли загрузка
   */
  /**
   * * Модель пагинации
   */
  pagination = ref(new PaginationModel());
  /**
   * * Текущая страница
   */
  current = () => this.pagination.value.Current;
  /**
   * * Кластеры
   */
  clusters = ref(new Array<RouteItemClusterModel>());
  /**
   * * Количество пропускаемых значений
   */
  skip = () => (this.pagination.value.Current - 1) * this.pagination.value.Size;
  /**
   * * Количество значений за одну загрузку
   */
  take = () => this.pagination.value.Size;
  isLoading = ref(false);
  /**
   * * Открыть
   */
  stopLoading = () => {
    this.isLoading.value = false;
  };
  apiUrl = "";
  constructor() {
    this.loadSettings();
  }

  mapCluster = (cluster: MapRouteClusterItemResponse) => {
    return new RouteItemClusterModel({
      Count: cluster.Count,
      Id: cluster.MapRoute?.Id,
      Title: cluster.MapRoute?.Title,
      Latitude: cluster.Position.Latitude,
      Longitude: cluster.Position.Longitude,
      DetailUrl: cluster.MapRoute?.PreviewUrl,
      Description: cluster.MapRoute?.Description,
      PreviewImages: cluster.MapRoute
        ? [
            new CropImageModel({
              Id: 1,
              CropImage: cluster.MapRoute.PreviewUrl,
            }),
          ]
        : [],
      ImageId: 0,
      Number: 0,
    });
  };
  async loadSettings() {
    try {
      // Запрашиваем файл appWebSettings.json из папки public
      const response = await fetch("/appWebSettings.json");

      // Проверяем, что запрос успешен
      if (!response.ok) {
        throw new Error("Не удалось загрузить настройки");
      }

      // Преобразуем ответ в JSON
      const settings = await response.json();

      // Сохраняем нужные параметры в переменные
      this.apiUrl = settings.ver;
    } catch (error) {
      console.error("Ошибка при загрузке настроек:", error);
    }
  }

  // /**
  //  * * Получить маршруты
  //  * @param filter
  //  */
  // async getRouteClusters(
  //   _filter: MapFilterModel
  // ): Promise<Array<RouteItemClusterModel>> {

  // }

  // /**
  //  * * Обновить кластеры
  //  */
  // refreshClusters = async () => {
  //   const api = this.useApiStore().api;
  //   // startLoading();
  //   await api.MapRouteService.refreshClusterAsync().finally(async () => {
  //     await this.getRouteClusters(
  //       new MapFilterModel({
  //         Zoom: this.filter.value.Zoom,
  //         NorthEast: this.filter.value.NorthEast,
  //         SouthEast: this.filter.value.SouthEast,
  //       })
  //     );
  //   });
  // };
  convertData(data: IncomingData[]): ConvertedData[] {
    return data.map(item => ({
      Id: item.id,
      Count: item.count,
      Type: item.type,
      LanguageId: item.languageId,
      Position: {
        Longitude: item.position.longitude,
        Latitude: item.position.latitude
      },
      MapRoute: item.mapRoute ? {
        Id: item.mapRoute.id,
        Title: item.mapRoute.title,
        Description: item.mapRoute.description,
        Type: item.mapRoute.type,
        Transmission: item.mapRoute.transmission,
        PreviewUrl: item.mapRoute.previewUrl,
        // Пример дополнительных полей, если они есть
        Distance: undefined,
        Price: undefined,
        CommentsCount: undefined,
        PointsCount: undefined,
        Owner: undefined,
        PassedStatus: undefined,
        FirstMapPoint: undefined,
        IsBuy: undefined,
        IsFavorite: undefined,
        DistanceByCurrentPosition: undefined
      } : undefined
    }));
  }
  getLocalizationFromCookies(): number {
    const lang = localStorage.getItem('lang');
    switch (lang) {
      case 'ru':
        return 0;
      case 'en':
        return 1;
      case 'lv':
        return 2;
      default:
        return 0; // Значение по умолчанию
    }
  }
  
  async getRouteClusters (value: MapFilterModel = null): Promise<ClusterResponse > {
  
    const requestData: ClusterRequest = {
      LeftTopPoint: {
        Longitude: "-180",
        Latitude: "90",
      },
      RightBottomPoint: {
        Longitude: "180",
        Latitude: "-90",
      },
      ZoomLevel: 1,
      Localization: this.getLocalizationFromCookies(),
    };
    if (value) {
      requestData.LeftTopPoint = {
        Longitude: value.NorthEast.lng,
        Latitude: value.NorthEast.lat,
      };
      requestData.RightBottomPoint = {
        Longitude: value.SouthEast.lng,
        Latitude: value.SouthEast.lat,
      };
      requestData.ZoomLevel = value.Zoom;
    }
    try {
      const response = await axios.post<ClusterResponse>(
        "https://dev-landing.artifactum.io/cluster-list",
        requestData
      );
      const resp = this.convertData((response.data as any).items) ;
      return resp
    } catch (error) {
      console.error("Error fetching cluster list:", error);
      throw error;
    }
  }

  // // Пример использования функции
  // getClusterList().then(data => {
  //     console.log('Cluster list:', data);
  // }).catch(error => {
  //     console.error('Error:', error);
  // });
}
// [
//   {
//     "id": 200465,
//     "count": 1,
//     "type": 0,
//     "geoHash": null,
//     "languageId": 0,
//     "position": {
//       "longitude": 29.62777871728516,
//       "latitude": 46.847181491696944
//     },
//     "mapRoute": {
//       "id": 8483,
//       "title": "Historical Route in Tiraspol Copy",
//       "description": "",
//       "type": 0,
//       "transmission": 0,
//       "previewUrl": "https://storage.yandexcloud.net/dev-artifactum-bucket//files/MapRoutes/8483/preview-f435d595-8102-47e9-9b39-0822cecac2cc.jpg",
//       "x": 29.630237,
//       "y": 46.84732,
//       "detailUrl": "https://storage.yandexcloud.net/dev-artifactum-bucket//files/MapRoutes/8483/detail-f435d595-8102-47e9-9b39-0822cecac2cc.jpg"
//     }
//   }
// ];

//   [      {
//         "Id": 197024,
//         "Count": 1,
//         "Type": 0,
//         "LanguageId": 0,
//         "Position": {
//           "Longitude": 29.612481590593237,
//           "Latitude": 46.84379461087283
//         },
//         "MapRoute": {
//           "Id": 8491,
//           "Title": "Translation testing",
//           "Description": "Route translation",
//           "Distance": 11703200.099514637,
//           "Type": 0,
//           "Transmission": 0,
//           "Price": 0.0000,
//           "CommentsCount": 0,
//           "PointsCount": 5,
//           "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8491/preview-fb3416fa-6c38-4c36-a307-181dc3ac2d0d.jpg",
//           "Owner": {
//             "UserId": 38,
//             "NickName": "astro-m",
//             "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
//           },
//           "PassedStatus": 0,
//           "FirstMapPoint": {
//             "MapPointId": 10063,
//             "Latitude": 46.84379461087283,
//             "Longitude": 29.612481590593237
//           },
//           "IsBuy": false,
//           "IsFavorite": false,
//           "DistanceByCurrentPosition": 0.0
//         }
//       }]


//   [{
//         "Id": 197022,
//         "Count": 3,
//         "Type": 0,
//         "LanguageId": 0,
//         "Position": {
//           "Longitude": 29.620605269196812,
//           "Latitude": 46.836733814937965
//         }
//       },
//       {
//         "Id": 197024,
//         "Count": 1,
//         "Type": 0,
//         "LanguageId": 0,
//         "Position": {
//           "Longitude": 29.612481590593237,
//           "Latitude": 46.84379461087283
//         },
//         "MapRoute": {
//           "Id": 8491,
//           "Title": "Translation testing",
//           "Description": "Route translation",
//           "Distance": 11703200.099514637,
//           "Type": 0,
//           "Transmission": 0,
//           "Price": 0.0000,
//           "CommentsCount": 0,
//           "PointsCount": 5,
//           "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8491/preview-fb3416fa-6c38-4c36-a307-181dc3ac2d0d.jpg",
//           "Owner": {
//             "UserId": 38,
//             "NickName": "astro-m",
//             "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
//           },
//           "PassedStatus": 0,
//           "FirstMapPoint": {
//             "MapPointId": 10063,
//             "Latitude": 46.84379461087283,
//             "Longitude": 29.612481590593237
//           },
//           "IsBuy": false,
//           "IsFavorite": false,
//           "DistanceByCurrentPosition": 0.0
//         }
//       }]
 