// import BaseLayout from './components/layouts/base-layout.vue';
import { log } from "console";
import BaseLayout from "./components/layouts/base-layout.vue";
import { PageAboutApp } from "./components/pages/about-app";
import { PageApp } from "./components/pages/app";
import { ContactsPage } from "./components/pages/contacts";
import { PageCountries } from "./components/pages/countries";
import { PageFeedback } from "./components/pages/feedback";
import { PageHome } from "./components/pages/home";
import { PageIdea } from "./components/pages/idea";
import { PageMain } from "./components/pages/main-screen";
import { PageOther } from "./components/pages/other";
import { PagePartners } from "./components/pages/partners";
import { PagePrivacyPolicy, PageTermsConditions } from "./components/pages/privacy-policy";
import Vue from "vue";
import VueRouter, {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import PolicyLayout from "./components/layouts/policy-layout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    name: "layout",
    path: "/",
    component: BaseLayout,
    children: [
      {
        name: "main",
        path: "",
        component: PageMain,
      },
      {
        name: "app",
        path: "app",
        component: PageApp,
      },
      {
        name: "home",
        path: "home",
        component: PageMain,
      },
      {
        name: "about-app",
        path: "about",
        component: PageAboutApp,
      },
      {
        name: "partners",
        path: "partners",
        component: PagePartners,
      },
      {
        name: "policy",
        path: "policy",
        component: PolicyLayout,
        children: [
          {
            name: "policy-privacy",
            path: "privacy-policy", // This will match the default "policy" route
            component: PagePrivacyPolicy,
          },
          {
            name: "policy-terms",
            path: "terms-conditions", // This will match "policy/terms-conditions"
            component: PageTermsConditions,
          },
        ],
      },
      {
        name: "feedback",
        path: "feedback",
        component: PageFeedback,
      },
      {
        name: "idea",
        path: "idea",
        component: PageIdea,
      },
      {
        name: "contacts",
        path: "contacts",
        component: ContactsPage,
      },
      {
        name: "countries",
        path: "countries",
        component: PageCountries,
      },
      {
        name: "home-child",
        path: "",
        component: PageHome,
      },
      {
        name: "other",
        path: "other/:id?",
        component: PageOther,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    // Если нужно установить заголовок страницы на основе мета-данных
    if (to.meta.title) {
      document.title = (to?.meta?.title as string) || " ";
    } else {
      // В противном случае можно установить дефолтный заголовок
      document.title = "Название вашего приложения";
    }

    // Вызов next() позволяет продолжить навигацию
    next();
  }
);

export default router;
